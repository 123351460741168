import Colors from "./colors";

export const darkTheme = {
  main: '#17191D',
  secondary: '#313237',
  header: '#343A40',
  openUserMenu: '#242A30',
  background : "black",
  
  light: false,

  mainFont: "white",
  secondaryFont: "white",
  Font:"black",
  darkborder:"5px",
  lightborder:"0px 5px 5px 0px",
  themebuttondark:"#17191D",
  themebuttonlight:"#272A2D",

  text: {
    main: Colors.white,
    secondary: Colors.black,
    normal: Colors.white,
    low:"#B8B9BD",
  },
  
  body: {
    background: '#313237',
    contentBoxes: '#343A40',
  },
  
  nav: {
    background: '#343A40',
    selected: '#313237',
    border: '#313237',
  },

  table: {
    main: '#343A40',
    text: Colors.white,
    border: '#5f6b7a',
    hover: '#454D75',
    important: '#E07800',
    importantHover: '#FCB938'
  },

  shadow: {
    gray: 'rgba(0, 0, 0, 0.25)',
  },

  footer: {
    backgroundColor: '#2e3338',
  },

  colors: {
    neutral: '#87A8FD',
    positive: '#B2E050',
    positiveShadeLight: '#4fae5b',
    attention: '#FCB938',
    negative: '#FD5D5D',
    inactive: '#c4c4c4',
    white: '#ffffff',
    black: '#1D1D1B',
    gray: 'rgba(0, 0, 0, 0.25)',
    darkGray: '#9d9d9d',
    darkerGray: '#f3f3f3',
    darkestGray: '#1d1d1b',
    lightGray: '#e2e2e2',
    lighterGray: '#4B5158',
    lightestGray: '#ffffff',
    text: '#ffffff'
  }
};