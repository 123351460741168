import React, { useState, useEffect } from 'react'
import {TooltipWrapper, TableWrapper, TableBody, TableRow,Maintable,TableTitelll,Space} from "./styled-component";
import InfoIcon from "../../icons/info-icon";
import ReactTooltip from "react-tooltip";
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import useWindowSize from "../../hooks/use-window-size";

import '../../styles/ui/ui.css';
import useDarkMode from '../../hooks/use-dark-mode/use-dark-mode';

type IncidenttableProps = {
    json: any;
    theme:any;
    fromWrapper: boolean;
};

const TableComponent: React.FC<IncidenttableProps> = ({json,theme, fromWrapper}) => {
    const device = useWindowSize();
	
    function showcol(col){
        if (device === "tablet" && col['DisplayinTablet'] === true)
            return true
        else if ((device === "wideDesktop" || device === 'desktop') && col['DisplayinWeb'] === true)
            return true
        else if(device === "mobile" && col['DisplayinMobile'] === true)
            return true
        else
            return false
    }
    return (
        <Maintable 
            style={{
                padding:fromWrapper === true? '0em 0.1em .3em' :'1.2em 1em 0.09px', 
                borderTopRightRadius: '0.625rem', 
                borderBottomLeftRadius: '0.625rem', 
                borderBottomRightRadius: '0.625rem', 
                fontFamily:"sans-serif", 
                // borderRadius: '0.625rem', 
                // border: 'none', 
                // boxShadow: 'rgb(0 0 0 / 5%) 0px 0px 15px'
            }}>
            {json && <>
                <TableTitelll style={{fontWeight: 'bold', textAlign: "start", fontSize: "22px", margin:fromWrapper===true? "" :"0.1em 0px 0.5em", }}>
                    {fromWrapper === true? "" : json['Title']}
                    {/* <TooltipWrapper data-tip data-for={json['HeadingTitle'] + "most-recent-incidents-tooltip"}>
                        <InfoIcon altText="Info icon tables" isLightTheme={true} />
                        <ReactTooltip id={json['HeadingTitle'] + "most-recent-incidents-tooltip"} place="top" type="dark" effect="solid">
                            <p>{json['HelpText']}</p>
                        </ReactTooltip>
                    </TooltipWrapper> */}
                </TableTitelll>
                <Table responsive={false} striped  borderless hover variant={theme==="light" ? "" : "dark"} style={{width: fromWrapper===true?"95%" : '', margin: fromWrapper?'auto' : ''}}>
                    {fromWrapper === true? "" :
                    <thead>
                        <tr style={{'backgroundColor': theme==="light" ? '#F2F4F8' : '#17191D',fontSize:"20px"}}>
                            {json['TableItemHeader'] && json['TableItemHeader'].filter(showcol).map((header: any[], i: React.Key) => {
                                return (
                                    <th 
                                        key={i}
                                        style={{textAlign: header['Align'],padding:"14px"}}
                                    >
                                        {header['HelpText'] !== "" ? 
                                            <div data-tip data-for={header['ColumnHeading'] + i + "header-for-table-top"}>
                                                {header['ColumnHeading']}
                                                <ReactTooltip id={header['ColumnHeading'] + i + "header-for-table-top"} place="top" type="dark" effect="solid">
                                                    <p>{header['ColumnHeading']}</p>
                                                </ReactTooltip>
                                            </div>
                                        :
                                            header['ColumnHeading']
                                        }
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    }
                    <Space ></Space>
                    <tbody>
                        {theme==="light"?<tr></tr>:""}
                        {json['TableItemRows']? json['TableItemRows'].map((row: any[], i: any) => {
                            return (
                                <tr key={'model_key'+i} style={{backgroundColor: '#FCFDFF'}} className='bg-white'>
                                    {row && row['data'].map((col: any[], col_i: any) => {
                                        return showcol(json['TableItemHeader'][col_i])?
                                            <td
                                                key={i+col_i} 
                                                style={{textAlign: json['TableItemHeader'][col_i]['Align'],verticalAlign:"middle",fontSize:"18px"}}
                                            >
                                                {col['backgroundColor']? 
                                                    <Badge
                                                        ref={(el) => {if (el) {el.style.setProperty('background-color', col['backgroundColor'], 'important');
                                                        }}}
                                                        style={{color: col['textColor']? col['textColor']: '', fontSize:Number(col['value'])?"20px":"18px"}}
                                                    >
                                                        {col['value']}
                                                    </Badge>    
                                                    :
                                                    col['value']
                                                }
                                            </td>
                                            :
                                            <></>
                                        }
                                    )}
                                </tr>)
                            }): ''}
                    </tbody>
                </Table>
                </>
            }
        </Maintable>
    )
}

export default TableComponent;
