const Typography = {
    text: {
      name: 'sans-serif',
      sizes: {
        extrasmall: '0.625rem',
        small: '0.75rem',
        normal: '0.875rem',
        large: '1rem',
        larger: '1.125rem',
        extralarge: '1.25rem',
        xxlarge: '1.563rem',
        xxxlarge: '2.375rem',
      },
      normal: '400',
      light: '300',
      semibold: '600',
      bold: '700',
      italic: 'italic',
      lineHeight: {
        // TODO Calculate font sizes and font-heights
        normal: '0.875rem',
        large: '1rem',
        larger: '1.4rem',
        extralarge: '1.8rem',
        xxlarge: '2rem',
        xxxlarge: '2.87rem',
      }
    },
    number: {
      name: 'PT Serif, serif',
      size: '1rem',
      normal: '400',
      bold: '700',
      italic: 'italic'
    }
  };
  
  export default Typography;
  