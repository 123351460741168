import React, { useState, useEffect } from 'react';
import TableComponent from './Table';
import useWindowSize from "../../hooks/use-window-size";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import '../../styles/ui/ui.css';
import './custom.css'

type IncidenttableProps = {
    json: any;
    theme:any;
    fromWrapper: boolean;
};

const TableTabComponent: React.FC<IncidenttableProps> = ({json,theme,fromWrapper}) => {
    const [key, setKey] = useState('home0');

    return ( 
        <div>{json && json['Tabbed']? 
            <div>
                <Tabs
                    id="controlled-tab-table"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className={`border-0 ${theme == 'light' ? "" : "darkTableClass"}`}
                >
                    {json && json['TableItem'].map((item, index) => {
                        return (
                            <Tab style={{color: 'red'}} className='border-0' eventKey={"home" + index} title={item['TableTabHeader']?item['TableTabHeader']: ""}>
                                <TableComponent 
                                    json={{Title: '', TableItemHeader: item['TableItemHeader'], TableItemRows: item['TableItemRows']}} 
                                    key={index}
                                    theme={theme}
                                    fromWrapper={fromWrapper}
                                />
                            </Tab>)
                    })}
                </Tabs></div>
                :
                json['TableItem'] && json['TableItem'][0]? <TableComponent fromWrapper={fromWrapper} theme={theme} json={{Title: json['TableItem'][0]['TableTabHeader'], TableItemHeader: json['TableItem'][0]['TableItemHeader'], TableItemRows: json['TableItem'][0]['TableItemRows']}}/>: ""}
        </div>)
}

export default TableTabComponent;
