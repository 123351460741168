import styled from 'styled-components';

import Typography from '../../styles/typography/typography';
import {PageHeaderProps as Props} from "./page-header";


export const StyledPageHeader = styled.h1`
  padding: 15px 0;
  text-align: ${(props: Props) =>
    props.styling?.textAlign && props.styling.textAlign};
  font-family: ${Typography.text.name};
  font-weight: ${Typography.text.semibold};
  font-size: ${Typography.text.sizes.xxxlarge};
  line-height: ${Typography.text.lineHeight.xxxlarge};
  color: white;
  color: ${({ theme }) => theme.mainFont};
  margin: 0;
`;
