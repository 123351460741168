import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as BaneNorLight } from './bane-nor-icon-light.svg';
import { ReactComponent as BaneNorDark } from './bane-nor-icon-dark.svg';

type BaneNorIconProps = {
    scale?: number;
    altText: string;
    light?: boolean;
    theme:any;
}

const BaneNorIcon: React.FC<BaneNorIconProps> = ({ scale = 1, altText, light ,theme}) => {
  return (
    <IconWrapper width={1.5 * scale} aria-label={altText}>
      {theme==="dark" ? <BaneNorLight /> : <BaneNorDark />}
    </IconWrapper>
  );
};

export default BaneNorIcon;