const Colors = {
    neutral: '#0041e6',
    positive: '#00AD09',
    positiveShadeLight: '#4fae5b',
    attention: '#e07800',
    negative: '#cf022b',
    inactive: '#c4c4c4',
    white: '#ffffff',
    black: '#1D1D1B',
    gray: '#cccccc',
    darkGray: '#9d9d9d',
    darkerGray: '#4B5158',
    darkestGray: '#1d1d1b',
    lightGray: '#e2e2e2',
    lighterGray: '#f3f3f3',
    lightestGray: '#ffffff',
    text: '#4b5158'
};

//Colors used in JSON

//#50C878 green
//#059F00 Islamic green
//#FF0000 red
//#FFA500 orange
//#FFFFFF white
//#1388BE blue
//#00549F dark blue
//#6BA2D4 light blue
//#FA8072 salmon
//#F3F3F3 light grey

export default Colors;