import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as Logo } from './akerbp-logo.svg';
import styled from "styled-components";

const StyledAkerBPLogo = styled(Logo)`
  
`

const StyledSecurityIconDark = styled(Logo)`
  
`

type AkerBPLogoProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  /* light or dark mode*/
  light?: boolean;
  theme:any;
};

const AkerBPLogo: React.FC<AkerBPLogoProps> = ({ scale = 1, altText, light,theme }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"marginTop": "0px", "width": "8rem", marginLeft: "-10px"}}>
      <StyledAkerBPLogo />
    </IconWrapper>
  );
};

export default AkerBPLogo;