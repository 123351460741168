import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as Applications } from './applications-icon.svg';
import { ReactComponent as ApplicationsDark } from './applications-icon-dark.svg';
import styled from "styled-components";

const StyledApplicationsIcon = styled(Applications)`
  
`
const StyledApplicationsIconDark = styled(ApplicationsDark)`
  
`

type ApplicationsIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  light?: boolean;
  theme:any;
};

const ApplicationsIcon: React.FC<ApplicationsIconProps> = ({ scale = 1, altText, light,theme }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      {theme==="light" ? <StyledApplicationsIcon /> : <StyledApplicationsIconDark /> }
    </IconWrapper>
  );
};

export default ApplicationsIcon;