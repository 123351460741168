import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as Security } from './security-icon.svg';
import { ReactComponent as SecurityDark} from './security-icon-dark.svg';
import styled from "styled-components";

const StyledSecurityIcon = styled(Security)`
  
`

const StyledSecurityIconDark = styled(SecurityDark)`
  
`

type SecurityIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  /* light or dark mode*/
  light?: boolean;
  theme:any;
};

const SecurityIcon: React.FC<SecurityIconProps> = ({ scale = 1, altText, light ,theme}) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      {theme==="light" ? <StyledSecurityIcon /> : <StyledSecurityIconDark />}
    </IconWrapper>
  );
};

export default SecurityIcon;