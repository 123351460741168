import styled from 'styled-components';
import Colors from '../../styles/colors/colors';

type Props = {
    styling?: {
      removeDefaultPadding?: boolean;
      mobileOffset?: boolean;
      backgroundColor?: string;
      backgroundColorDesktopOnly?: string;
      backgroundColorTabletOnly?: string;
      backgroundColorMobileOnly?: string;
      paddingAllSides?: string;
      paddingTop?: string;
      paddingBottom?: string;
      paddingLeft?: string;
      paddingRight?: string;
      paddingAllSidesDesktopOnly?: string;
      paddingTopDesktopOnly?: string;
      paddingBottomDesktopOnly?: string;
      paddingLeftDesktopOnly?: string;
      paddingRightDesktopOnly?: string;
      paddingAllSidesTabletOnly?: string;
      paddingTopTabletOnly?: string;
      paddingBottomTabletOnly?: string;
      paddingLeftTabletOnly?: string;
      paddingRightTabletOnly?: string;
      paddingAllSidesMobileOnly?: string;
      paddingTopMobileOnly?: string;
      paddingBottomMobileOnly?: string; 
      paddingLeftMobileOnly?: string;
      paddingRightMobileOnly?: string;
      marginAllSides?: string;
      marginTop?: string;
      marginBottom?: string;
      marginLeft?: string;
      marginRight?: string;
      marginAllSidesDesktopOnly?: string;
      marginTopDesktopOnly?: string;
      marginBottomDesktopOnly?: string;
      marginLeftDesktopOnly?: string;
      marginRightDesktopOnly?: string;
      marginAllSidesTabletOnly?: string;
      marginTopTabletOnly?: string;
      marginBottomTabletOnly?: string;
      marginLeftTabletOnly?: string;
      marginRightTabletOnly?: string;
      marginAllSidesMobileOnly?: string;
      marginTopMobileOnly?: string;
      marginBottomMobileOnly?: string;
      marginLeftMobileOnly?: string;
      marginRightMobileOnly?: string;
    };
};

const backgroundColors = {
  contentLightGray: Colors.lightGray,
  contentLighterGray: Colors.lighterGray,
  contentLightestGray: Colors.lightestGray,
  contentWhite: Colors.white
};

const backgroundColorsTablet = Object.create(backgroundColors);
const backgroundColorsMobile = Object.create(backgroundColors);

const spacing = {
  none: '0',
  lowest: '0.5rem',
  lower: '1rem',
  low: '1.25rem',
  medium: '1.5rem',
  high: '2rem',
  higher: '2.5rem',
  highest: '3rem'
};

const spacingTablet = Object.create(spacing);
const spacingMobile = Object.create(spacing);

export const StyledContentContainer = styled.div`
  background-color: ${(props: Props) =>
    props.styling?.backgroundColor && backgroundColors[props.styling.backgroundColor]};

  padding: ${(props: Props) =>
    props.styling?.paddingAllSides && spacing[props.styling.paddingAllSides]};
  padding-top: ${(props: Props) => props.styling?.paddingTop && spacing[props.styling.paddingTop]};
  padding-bottom: ${(props: Props) =>
    props.styling?.paddingBottom && spacing[props.styling.paddingBottom]};
  padding-left: ${(props: Props) =>
    props.styling?.paddingLeft && spacing[props.styling.paddingLeft]};
  padding-right: ${(props: Props) =>
    props.styling?.paddingRight && spacing[props.styling.paddingRight]};

  margin: ${(props: Props) => props.styling?.marginAllSides && spacing[props.styling.marginAllSides]};
  margin-top: ${(props: Props) => props.styling?.marginTop && spacing[props.styling.marginTop]};
  margin-bottom: ${(props: Props) =>
    props.styling?.marginBottom && spacing[props.styling.marginBottom]};
  margin-left: ${(props: Props) => props.styling?.marginLeft && spacing[props.styling.marginLeft]};
  margin-right: ${(props: Props) =>
    props.styling?.marginRight && spacing[props.styling.marginRight]};

  @media (min-width: 1024px) {
    background-color: ${(props: Props) =>
      props.styling?.backgroundColorDesktopOnly &&
      backgroundColors[props.styling.backgroundColorDesktopOnly]};

    padding: ${(props: Props) =>
      props.styling?.paddingAllSidesDesktopOnly && spacing[props.styling.paddingAllSidesDesktopOnly]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTopDesktopOnly && spacing[props.styling.paddingTopDesktopOnly]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottomDesktopOnly && spacing[props.styling.paddingBottomDesktopOnly]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeftDesktopOnly && spacing[props.styling.paddingLeftDesktopOnly]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRightDesktopOnly && spacing[props.styling.paddingRightDesktopOnly]};

    margin: ${(props: Props) =>
      props.styling?.marginAllSidesDesktopOnly && spacing[props.styling.marginAllSidesDesktopOnly]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTopDesktopOnly && spacing[props.styling.marginTopDesktopOnly]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottomDesktopOnly && spacing[props.styling.marginBottomDesktopOnly]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeftDesktopOnly && spacing[props.styling.marginLeftDesktopOnly]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRightDesktopOnly && spacing[props.styling.marginRightDesktopOnly]};
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    background-color: ${(props: Props) =>
      props.styling?.backgroundColor && backgroundColorsTablet[props.styling.backgroundColor]};
    background-color: ${(props: Props) =>
      props.styling?.backgroundColorTabletOnly &&
      backgroundColorsTablet[props.styling.backgroundColorTabletOnly]};

    padding: ${(props: Props) =>
      props.styling?.paddingAllSides && spacingTablet[props.styling.paddingAllSides]};
    padding: ${(props: Props) =>
      props.styling?.paddingAllSidesTabletOnly && spacingTablet[props.styling.paddingAllSidesTabletOnly]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTop && spacingTablet[props.styling.paddingTop]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTopTabletOnly && spacingTablet[props.styling.paddingTopTabletOnly]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottom && spacingTablet[props.styling.paddingBottom]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottomTabletOnly && spacingTablet[props.styling.paddingBottomTabletOnly]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeft && spacingMobile[props.styling.paddingLeft]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeftTabletOnly && spacingTablet[props.styling.paddingLeftTabletOnly]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRight && spacingTablet[props.styling.paddingRight]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRightTabletOnly && spacingTablet[props.styling.paddingRightTabletOnly]};

    margin: ${(props: Props) =>
      props.styling?.marginAllSides && spacingTablet[props.styling.marginAllSides]};
    margin: ${(props: Props) =>
      props.styling?.marginAllSidesTabletOnly && spacingTablet[props.styling.marginAllSidesTabletOnly]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTop && spacingTablet[props.styling.marginTop]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTopTabletOnly && spacingTablet[props.styling.marginTopTabletOnly]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottom && spacingTablet[props.styling.marginBottom]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottomTabletOnly && spacingTablet[props.styling.marginBottomTabletOnly]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeft && spacingTablet[props.styling.marginLeft]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeftTabletOnly && spacingTablet[props.styling.marginLeftTabletOnly]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRight && spacingTablet[props.styling.marginRight]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRightTabletOnly && spacingTablet[props.styling.marginRightTabletOnly]};
  }

  @media (max-width: 599px) {
    background-color: ${(props: Props) =>
      props.styling?.backgroundColor && backgroundColorsMobile[props.styling.backgroundColor]};
    background-color: ${(props) =>
      props.styling?.backgroundColorMobileOnly &&
      backgroundColorsMobile[props.styling.backgroundColorMobileOnly]};

    padding: ${(props: Props) =>
      props.styling?.paddingAllSides && spacingMobile[props.styling.paddingAllSides]};
    padding: ${(props: Props) =>
      props.styling?.paddingAllSidesMobileOnly && spacingMobile[props.styling.paddingAllSidesMobileOnly]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTop && spacingMobile[props.styling.paddingTop]};
    padding-top: ${(props: Props) =>
      props.styling?.paddingTopMobileOnly && spacingMobile[props.styling.paddingTopMobileOnly]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottom && spacingMobile[props.styling.paddingBottom]};
    padding-bottom: ${(props: Props) =>
      props.styling?.paddingBottomMobileOnly && spacingMobile[props.styling.paddingBottomMobileOnly]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeft && spacingMobile[props.styling.paddingLeft]};
    padding-left: ${(props: Props) =>
      props.styling?.paddingLeftMobileOnly && spacingMobile[props.styling.paddingLeftMobileOnly]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRight && spacingMobile[props.styling.paddingRight]};
    padding-right: ${(props: Props) =>
      props.styling?.paddingRightMobileOnly && spacingMobile[props.styling.paddingRightMobileOnly]};

    margin: ${(props: Props) =>
      props.styling?.marginAllSides && spacingMobile[props.styling.marginAllSides]};
    margin: ${(props: Props) =>
      props.styling?.marginAllSidesMobileOnly && spacingMobile[props.styling.marginAllSidesMobileOnly]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTop && spacingMobile[props.styling.marginTop]};
    margin-top: ${(props: Props) =>
      props.styling?.marginTopMobileOnly && spacingMobile[props.styling.marginTopMobileOnly]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottom && spacingMobile[props.styling.marginBottom]};
    margin-bottom: ${(props: Props) =>
      props.styling?.marginBottomMobileOnly && spacingMobile[props.styling.marginBottomMobileOnly]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeft && spacingMobile[props.styling.marginLeft]};
    margin-left: ${(props: Props) =>
      props.styling?.marginLeftMobileOnly && spacingMobile[props.styling.marginLeftMobileOnly]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRight && spacingMobile[props.styling.marginRight]};
    margin-right: ${(props: Props) =>
      props.styling?.marginRightMobileOnly && spacingMobile[props.styling.marginRightMobileOnly]};
  }
`;
