import {useState} from "react";
import {AxiosResponse} from "axios";
import axios from "axios";

type AxioAction = (...body: any) => Promise<AxiosResponse>;

export type ReturnState = {
  loading: boolean,
  error: boolean,
  data: any
}

export type ReturnFunc = (body?: any) => void;

export type useApiActionReturnType = [ReturnState, ReturnFunc];

export function useApiAction(action: AxioAction, initStateLoading: boolean = true, source?: any)
  : useApiActionReturnType {
  
  const [state, setState] = useState({data: null, loading: initStateLoading, error: false});
  
  const performAction = async (body = null) => {
    
    try {
      setState((prevState: any) => {
        return {...prevState, loading: true}
      });
      const response = await action(body, source);      
      setState({...state, data: response.data, loading: false});
    } catch (e) {
      if (!axios.isCancel(e)) {     
        setState({...state, error: e});
      }
      
    }
    
  };
  
  return [state, performAction];
}