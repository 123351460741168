import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as ExecutiveDashboardLight } from './ExecutiveDashboard-light.svg';
import { ReactComponent as ExecutiveDashboardDark } from './ExecutiveDashboard-dark.svg';
import styled from "styled-components";

const StyledExecutiveDashboardIcon = styled(ExecutiveDashboardLight)`

`

const StyledExecutiveDashboardIconDark = styled(ExecutiveDashboardDark)`

`

type ExecutiveDashboardIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  light?: boolean;
  theme:any;
};

const ExecutiveDashboardIcon: React.FC<ExecutiveDashboardIconProps> = ({ scale = 1, altText, light,theme }) => {
  return (
    <IconWrapper width={12 * scale} aria-label={altText}>
      {theme==="light" ? <StyledExecutiveDashboardIconDark /> : <StyledExecutiveDashboardIcon />}
    </IconWrapper>
  );
};

export default ExecutiveDashboardIcon;