import React, { useRef, useEffect, useState } from "react";
import {
  StatusWrapper
} from "./styled-components";
import ContentContainer from "../content-container";
import {withTheme} from "styled-components";
import {Line} from "react-chartjs-2";
import ReactTooltip from 'react-tooltip';

// todo: implement get_data the way it is in QualysBarChart
export type StatusWrapperProp = {
  styling?: {
    minWidth?: string;
    width?: string;
  }
}

type LineChartProps = {
  lineData: any,
  options: any,
  tooltip?: string,
  theme: {
    light: boolean,
  },
  axiscolour:any,
  fromKinderegg: boolean
};

const initOptions = {
  tension: 0,
  scales: {
    y: {
      suggestedMin: 0,
      ticks: {
        precision: 0,
        color:0?"black":"#B8B9BD"
      },
      grid: {
        drawBorder: false,
        display: false
      }
    },
    x: {
      ticks: {
        color:0?"black":"#B8B9BD"
      },
      grid: {
        drawBorder: false,
        display: false
      }
    },
  },
  elements: {
    point:{
      radius: 0
    }
  },
  plugins: {},
  maintainAspectRatio: false
}

const LineChart: React.FC<LineChartProps> = ({ lineData, theme,axiscolour, tooltip, fromKinderegg }) => {
  const inputRef = useRef(null);
  const [options, setOptions] = useState(initOptions);
  const annotation1 = []

  useEffect(() => {
    if (inputRef && inputRef['current'] && inputRef['current']['scales']){
      let y_axis = inputRef['current']['scales']['y']['ticks']
      let min = 0 
      let max = 0
      y_axis.map((item, index) =>{
        if (index%2 == 0){
          if (index != y_axis.length-1){
            let diff = y_axis[index+1]['value'] - y_axis[index]['value'] 
            min = y_axis[index]['value'] + (diff/2)
            max = y_axis[index+1]['value'] + (diff/2)
            annotation1.push({
              type: 'box',
              drawTime: 'beforeDraw',
              yMin: min,
              yMax: max,
              borderColor: axiscolour?'rgb(242, 244, 248, 0.9)':"#1F2125",
              borderWidth: 1,
              backgroundColor: axiscolour?"#F2F4F8":"#1F2125",
            })
          }
      }})}

    setOptions({
      tension: lineData['Tension']? lineData['Tension']: 0,
      scales: {
        y: {
          suggestedMin: 0,
          ticks: {
            precision: 0,
            color:axiscolour?"black":"#B8B9BD"
          },
          grid: {
            drawBorder: false,
            display: false
          }
        },
        x: {
          ticks: {
            color:axiscolour?"black":"#B8B9BD"
          },
          grid: {
            drawBorder: false,
            display: false
          }
        },
      },
      elements: {
        point:{
          radius: 0
        }
      },
      plugins: {
        legend: {
          display:  lineData['datasets'].map((post, index) =>index['label'])===""?false:lineData['legend']['display'],
          position: lineData['legend']['position'] != ''? lineData['legend']['position']: "top",
          align: lineData['legend']['alignment'],
          labels: {
            boxHeight: 1,
            color:axiscolour?"black":"#B8B9BD"
          }
        },
        title: {
          display: true,
          text: tooltip,
          color:axiscolour?"black":"white"
        },
        annotation: {
          annotations: annotation1
        }
      },
      maintainAspectRatio: false
    }) 
  }, [inputRef,axiscolour ])

  
  return (
    <div data-tip data-for={tooltip}>
      <StatusWrapper style={ fromKinderegg ? {} : {minWidth: '32rem'}}>
        <ContentContainer className="bar-chart-section">
          {/*@ts-ignore*/}
          <Line ref={inputRef} type={"line"} data={lineData} options={options} />
        </ContentContainer>
        {tooltip &&
          <ReactTooltip place="top" type="dark" effect="solid" id={tooltip}>
            {tooltip}
          </ReactTooltip>
        }
      </StatusWrapper>
    </div>
  );
};

export default withTheme(LineChart);