import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as Logo } from './soprasteria-logo.svg';
import { ReactComponent as Logodark } from './soprasteria-logo-dark.svg';
import styled from "styled-components";

const StyledSoprasteria = styled(Logo)`
  
`

const StyledSecurityIconDark = styled(Logodark)`
  
`

type SoprasteriaProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  /* light or dark mode*/
  light?: boolean;
  theme:any;
};

const Soprasteria: React.FC<SoprasteriaProps> = ({ scale = 1, altText, light ,theme}) => {
  return (
    <IconWrapper width={8 * scale} height={3  * scale} aria-label={altText} style={{"marginTop": "-10px", marginLeft: "-12px"}}>
     {theme==="light"?<StyledSoprasteria />:<StyledSecurityIconDark/>} 
    </IconWrapper>
  );
};

export default Soprasteria;