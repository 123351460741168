import config from '../constants/config';

export const msalConfig = {
    auth: {
        clientId: config.clientId,
        redirectUri: config.redirectUri
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = (domainHint: string) => {
    return {
        scopes: config.scopes,
        domainHint: domainHint,
    }
}

export const interceptorLoginRequest = () => {
    return {
        scopes: config.scopes,
    }
}


// for fetching from the Microsoft graph API (gives user info about the logged in account)
export const graphConfig = {
    graphEndpoint: `Enter_the_Graph_Endpoint_Here/v1.0/me`,
}