import styled from 'styled-components';
import {StatusWrapperProp as Props} from "./line-chart";

export const StatusWrapper = styled.div`
  display: grid;
  color: ${({ theme }) => theme.text.main};
  margin-top:30px;
  margin-bottom:30px;
  width: ${(props: Props) =>
    props.styling?.width && (props.styling.width)};
  min-width: ${(props: Props) =>
    props.styling?.minWidth && (props.styling.minWidth)};
  height: 19rem;
  grid-template-columns: 10rem 1fr;
  grid-template-rows:
        0.5rem
        1fr
        0.5rem;
  grid-template-areas:
        '. .'
        'chart chart'
        '. .';
  border-radius: 0.625rem;
  // border: none;
  // box-shadow: rgb(0 0 0 / 5%) 0px 0px 15px;

  .bar-chart-section {
    grid-area: chart;
  }
  background-color: ${({ theme }) => theme.main};
  /*box-shadow: 0px 4px 3px ({ theme }) => theme.light ? Colors.gray : 'rgba(0, 0, 0, 0.25)';*/
  text-decoration: none;
  padding: 10px;
    
  @media (max-width: 640px) {
    display: grid;
    min-width: auto;
    height: 18rem;
    grid-template-columns: auto;
    grid-template-rows:
        auto;
    grid-template-areas:
        '.'
        'chart'
        '.';
    border-radius: 0.25rem;
    width: 90vw;
    grid-template-columns: auto;
    padding: 0;
    
    .bar-chart-section {
    
    }
    
  }
    
`;

export const ChartDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 10rem;
`;

export const DescriptionTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;