import { useEffect, useState } from 'react';

export default function useDarkMode() {
  const [ theme, setTheme ] = useState('light');

  // TODO Figure out why theme is not sending if toggleTheme is not called in the same component

  // @ts-ignore
  const setMode = mode => {
    window.localStorage.setItem('theme', mode);
    setTheme(mode)
  };
  
  // toggle theme between ligh and dark. also runs function to store in localstorage
  const toggleTheme = (e) => {
    if( e === 'light') {
      setMode('light')
    } else {
      setMode('dark')
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    if (localTheme) {
      setTheme(localTheme);
    } else {
      setMode('light')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return [theme, toggleTheme]
};