import React from 'react';
import PropTypes from 'prop-types';

import { StyledPageHeader } from './styled-components';

export type PageHeaderProps = {
  children?: string;
  styling?: {
    textAlign?: string;
  };
};

const PageHeader: React.FC<PageHeaderProps> = ({ children, styling }) => {
  return <StyledPageHeader styling={styling}>{children}</StyledPageHeader>;
}

PageHeader.propTypes = {
  children: PropTypes.string,
  styling: PropTypes.shape({
    /** Sets bakground-color for all devices. One of 'contentWhite', 'contentLightestGray', 'contentLighterGray', 'contentLightGray' (Same goes for background overrides bellow)*/
    textAlign: PropTypes.oneOf([
      'center',
      'right',
      'left'
    ])
  })
};

export default PageHeader;
