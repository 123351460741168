import React, { useState, useEffect } from "react";

import { OverviewGridLayout } from "./styled-components";
import ReactTooltip from "react-tooltip";
import ChartDonut from "./chart";
import Text from "../text";
import TeamEagle from "../../icons/team-icon-svg/TeamEagle.png";
import TeamFalcon from "../../icons/team-icon-svg/TeamFalcon.png";
import TeamFlamingo from "../../icons/team-icon-svg/TeamFlamingo.png";
import TeamLeopard from "../../icons/team-icon-svg/TeamLeopard.png";
import TeamPlatypus from "../../icons/team-icon-svg/TeamPlatypus.png";
import TeamBull from "../../icons/team-icon-svg/teambull.png";
import TeamTiger from "../../icons/team-icon-svg/TeamTiger.png";
import TeamWolverine from "../../icons/team-icon-svg/TeamWolverine.png";
import akerbp_drilling from "../../icons/team-icon-svg/akerbp_drilling.png";
import akerbp_exploration from "../../icons/team-icon-svg/akerbp_exploration.png";
import akerbp_operations from "../../icons/team-icon-svg/akerbp_operations.png";
import akerbp_other from "../../icons/team-icon-svg/akerbp_other.png";
import oslokommune_logo from "../../icons/team-icon-svg/Oslo_kommune_logo.png";
import teamshark_logo from "../../icons/team-icon-svg/TeamShark.png";
import CoEServiceNow from "../../icons/team-icon-svg/CoEServiceNow.png";
import {
  ChartDescriptionWrapper,
  DescriptionTextWrapper,
  StatusWrapper,
} from "./styled-components";
import ContentContainer from "../content-container";
import DrammenKommuneIcon from "../../icons/drammenKommuneIcon";

type SpeedometerProps = {
  json: any;
  noSpace: boolean;
  device: any;
  fromWrapper: boolean;
  theme: any;
};

const Speedometer: React.FC<SpeedometerProps> = ({
  json,
  noSpace = false,
  device,
  fromWrapper,
  theme,
}) => {
  const lengthof_speedometer = (json && json["SpeedometerItem"]).length;

  function get_team_logo(team) {
    switch (team) {
      case "TeamEagle":
        return <img src={TeamEagle} style={{ width: "75%" }} alt="TeamEagle" />;
      case "TeamFalcon":
        return (
          <img src={TeamFalcon} style={{ width: "60%" }} alt="TeamFalcon" />
        );
      case "TeamFlamingo":
        return (
          <img src={TeamFlamingo} style={{ width: "70%" }} alt="TeamFlamingo" />
        );
      case "TeamLeopard":
        return (
          <img
            src={TeamLeopard}
            style={{ width: fromWrapper === true ? "65%" : "60%" }}
            alt="TeamLeopard"
          />
        );
      case "TeamPlatypus":
        return (
          <img src={TeamPlatypus} style={{ width: "70%" }} alt="TeamPlatypus" />
        );
      case "TeamTiger":
        return (
          <img
            src={TeamTiger}
            style={{ width: fromWrapper === true ? "70%" : "60%" }}
            alt="TeamTiger"
          />
        );
      case "TeamWolverine":
        return (
          <img
            src={TeamWolverine}
            style={{ width: "70%" }}
            alt="TeamWolverine"
          />
        );
      case "akerbp_drilling":
        return (
          <img
            src={akerbp_drilling}
            style={{ width: "80%", marginBottom: "20px" }}
            alt="akerbp_drilling"
          />
        );
      case "akerbp_exploration":
        return (
          <img
            src={akerbp_exploration}
            style={{ width: "80%", marginBottom: "15px" }}
            alt="akerbp_exploration"
          />
        );
      case "akerbp_operations":
        return (
          <img
            src={akerbp_operations}
            style={{ width: "70%", marginBottom: "20px" }}
            alt="akerbp_operations"
          />
        );
      case "akerbp_other":
        return (
          <img
            src={akerbp_other}
            style={{ width: "70%", marginBottom: "20px" }}
            alt="akerbp_other"
          />
        );
      case "oslokommune_logo":
        return (
          <img
            src={oslokommune_logo}
            style={{ width: "70%" }}
            alt="oslokommune_logo"
          />
        );
      case "TeamShark":
        return (
          <img
            src={teamshark_logo}
            style={{ width: "70%" }}
            alt="teamshark_logo"
          />
        );
      case "TeamBull":
        return (
          <img src={TeamBull} style={{ width: "70%" }} alt="teambull_logo" />
        );
      case "CoEServiceNow":
        return (
          <img
            src={CoEServiceNow}
            style={{ width: "70%" }}
            alt="teamshark_logo"
          />
        );
      case "DrammenKommune":
        return (
          <div style={{ width: "fit-content", marginLeft: "56px" }}>
            <DrammenKommuneIcon scale={6} altText="drammen_kommuneIcon logo" />
          </div>
        );
      default:
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              margin: fromWrapper === true ? "12%" : "",
              marginTop: fromWrapper === true ? "28%" : "9%",
              overflow: "auto",
            }}
          >
            <h1
              style={{
                color: theme === "light" ? "black" : "white",
                fontSize: fromWrapper === true ? "25px" : "",
              }}
            >
              {team}
            </h1>
          </div>
        );
    }
  }
  return (
    <OverviewGridLayout
      style={{
        gridTemplateColumns: noSpace
          ? "repeat(auto-fit, minmax(10rem, 1fr))"
          : "repeat(auto-fit, minmax(20rem, 1fr))",
      }}
      data-tip
      data-for="service-area-charts-tooltip"
      fromWrapper={fromWrapper}
    >
      {device !== "mobile" ? (
        json && json["SpeedometerItem"] && json["SpeedometerImage"] ? (
          <StatusWrapper fromWrapper={fromWrapper}>
            <ContentContainer className="service-chart-header-section">
              <Text size="large"> </Text>
            </ContentContainer>
            <ContentContainer className="service-chart-section">
              {get_team_logo(json["SpeedometerImage"])}
            </ContentContainer>
            <ContentContainer className="service-chart-description-section">
              <ChartDescriptionWrapper></ChartDescriptionWrapper>
            </ContentContainer>
          </StatusWrapper>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {json &&
        json["SpeedometerItem"] &&
        json["SpeedometerItem"].map((service: any, index) => {
          let isDifferent = false;

          if (service["ColorSections"].length > 1) {
            isDifferent = true;
          }

          return (
            <ChartDonut
              key={service["ItemHeading"]}
              id={
                service["ItemHeading"].replace(/ /g, "") +
                String(index) +
                String(Math.random().toString(36).slice(2, 7))
              }
              header={service["ItemHeading"]}
              alerts={service}
              showNumber={isDifferent}
              fromWrapper={fromWrapper}
              bold={service["Bold"]}
              italic={service["Italic"]}
              fontsize={service["FontSize"]}
              speedometerlegth={lengthof_speedometer}
            />
          );
        })}
      {/* <ReactTooltip id="service-area-charts-tooltip" place="top" type={"light"} effect="solid">
                <p>Shows if there is any priority 1 or 2 incidents related to the specified Service Areas</p>
            </ReactTooltip> */}
    </OverviewGridLayout>
  );
};

export default Speedometer;
