const isLocalhost = window.location.href.indexOf('localhost') > -1;

const SN_Url = {
    getFormattingApi: "https://soprasteriadev.service-now.com/api/x_steas_executive/2s_executive_dashboard/GetFormatting",
    postRequestRoleApi: "https://soprasteriadev.service-now.com/api/x_steas_executive/2s_executive_dashboard/PostStringMessage"
}

const environments = {
    localhost: {
        api: {
            urlHome: SN_Url.getFormattingApi,
            urlRequestRole: SN_Url.postRequestRoleApi,
            userInfoUri: 'https://graph.microsoft.com/oidc/userinfo'
        },
        environment: 'localhost',
        clientId: 'f5be961d-36fb-4ae0-90cb-78083ced1a4e',
        redirectUri: 'http://localhost:3000/'
    },
    test: {
        api: {
            // uri: 'https://soprasteriatest.service-now.com/api/x_steas_executive/2s_executive_dashboard/GetFormatting',
            urlHome: SN_Url.getFormattingApi.replace("dev.", "test."),
            urlRequestRole: SN_Url.postRequestRoleApi.replace("dev.", "test."),
            userInfoUri: 'https://graph.microsoft.com/oidc/userinfo'
        },
        environment: 'test',
        clientId: 'f5be961d-36fb-4ae0-90cb-78083ced1a4e',
        redirectUri: 'https://execdash-frontend-sn-test.azurewebsites.net/'
    },
    development: {
        api: {
            // uri: 'https://soprasteriadev.service-now.com/api/x_steas_executive/2s_executive_dashboard/GetFormatting',
            urlHome: SN_Url.getFormattingApi,
            urlRequestRole: SN_Url.postRequestRoleApi,
            userInfoUri: 'https://graph.microsoft.com/oidc/userinfo'
        },
        environment: 'dev',
        clientId: 'f5be961d-36fb-4ae0-90cb-78083ced1a4e',
        redirectUri: 'https://execdash-frontend-sn-dev.azurewebsites.net/'
    },
    prod: {
        api: {
            // uri:  'https://soprasteria.service-now.com/api/x_steas_executive/2s_executive_dashboard/GetFormatting',
            urlHome: SN_Url.getFormattingApi.replace("dev.", "."),
            urlRequestRole: SN_Url.postRequestRoleApi.replace("dev.", "."),
            userInfoUri: 'https://graph.microsoft.com/oidc/userinfo'
        },
        environment: 'production',
        clientId: 'f5be961d-36fb-4ae0-90cb-78083ced1a4e',
        redirectUri: 'https://execdash-frontend-sn-prod.azurewebsites.net/'
    },
    production: {
        api: {
            // uri:  'https://soprasteria.service-now.com/api/x_steas_executive/2s_executive_dashboard/GetFormatting',
            urlHome: SN_Url.getFormattingApi.replace("dev.", "."),
            urlRequestRole: SN_Url.postRequestRoleApi.replace("dev.", "."),
            userInfoUri: 'https://graph.microsoft.com/oidc/userinfo'
        },
        environment: 'production',
        clientId: 'f5be961d-36fb-4ae0-90cb-78083ced1a4e',
        redirectUri: 'https://execdash.soprasteria.no/'
    },
};

export const getEnvironment = (
    uri = window.location.href,
    isLocalhost = false
) => {
    switch (true) {
        case isLocalhost:
            return environments.localhost;
        case uri.indexOf('-test') > -1:
            return environments.test;
        case uri.indexOf('-dev') > -1:
            return environments.development;
        case uri.indexOf('-prod') > -1:
            return environments.prod;
        default:
            return environments.production;
    }
};

const config = {
    ...getEnvironment(window.location.href, isLocalhost),
    scopes: ['openid', 'profile', 'offline_access']
};

export default config;