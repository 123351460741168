import axios from "axios";
import config from "../constants/config";

function scan(obj: any) {
    let k;
    if (obj instanceof Object) {
        for (k in obj){
            if (obj.hasOwnProperty(k)){
                //recursive call to scan property
                scan( obj[k] );
                if (obj[k] !== null && Object.entries(obj).length === 0 && obj.constructor === Object) {
                    obj[k] = ''
                }
            }
        }
    } else {
        //not an Object so obj[k] here is a value
    }
}

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    scan(response);
    return response;
}, function (error) {
    return Promise.reject(error);
});

// SN API
export async function getSNJSON(Propes) {
  let Request_Type = Propes[0];
  let URL = Propes[1];
  let Qurey = Propes[2];
  let Payload = Propes[3];
  
    function getURL(URL) {
        switch (URL) {
            case "urlHome":
            return config.api.urlHome;
            case "urlRequestRole":
            return config.api.urlRequestRole+Qurey;
        }
    }
    // return axios.get(config.api["urlHome"]);
    function Request(Request_Type) {
        switch (Request_Type) {
          case "Get":
            return axios.get(getURL(URL));
          case "Post":
            return axios.post(getURL(URL), Payload);
        }
      }
      return Request(Request_Type);
}