import styled from "styled-components";

export const TableRow = styled.tr`
  background-color: ${({ theme }) => theme.table.main};
  color: ${({ theme }) => theme.table.text};
  
  && th {
    background-color: ${({ theme }) => theme.main};
    border: none;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
  }
  
  && td {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
  }

  .incident-priority {
    text-align: center;
  }
`;

export const TableBody = styled.tbody`
  && tr:nth-child(odd) td {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.table.text};
    border: none;
  }

  && tr:nth-child(odd):hover td {
    background-color: ${({ theme }) => theme.table.hover};
  }

  && tr:nth-child(odd).open-incident td {
    background-color: ${({ theme }) => theme.table.important};
    color: ${({ theme }) => theme.colors.black};
  }

  && tr:nth-child(odd).open-incident:hover td {
    background-color: ${({ theme }) => theme.table.importantHover};
    color: ${({ theme }) => theme.colors.black};
  }
  
  && tr:nth-child(even) td {
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.table.text};
    border: none;
  }

  && tr:nth-child(even):hover td {
    background-color: ${({ theme }) => theme.table.hover};
  }

  && tr:nth-child(even).open-incident td {
    background-color: ${({ theme }) => theme.table.important};
    color: ${({ theme }) => theme.colors.black};
  }

  && tr:nth-child(even).open-incident:hover td {
    background-color: ${({ theme }) => theme.table.importantHover};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const TableWrapper = styled.div`
  && caption {
    text-align: start;
    font-size: 1.5em;
    margin: 1em 0 0.5em 0;
    color: ${({theme}) => theme.secondaryFont};
  }

  .sort-icon-wrapper {
    display: inline-block;

    svg {
      display: flex;
    }
  }

  .ascending {
    div {
      &:nth-child(2) {
        visibility: hidden;
      }
    }
  }

  .descending {
    div {
      &:nth-child(1) {
        visibility: hidden;
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: absolute;
  margin-left: 0.5em;
`;
export const Maintable = styled.div`
   background-color: ${({ theme }) => theme.main};
`;
export const TableTitelll = styled.div`
    color: ${({ theme }) => theme.text.main};
`;
export const Space = styled.div`
border-bottom: 10px solid ${({ theme }) => theme.main};
`;

