import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import ApplicationsIcon from "../../icons/applications-icon";
import SecurityIcon from "../../icons/security-icon";
import ItSpendIcon from "../../icons/it-spend-icon";
import { HiDownload } from "react-icons/hi";
import { BsBoxArrowRight, BsExclamation } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import {
  MassageContainer,
  MassageContainerText1,
  PopUpCloseButton,
} from "./StyledComponent";

import { IconContext } from "react-icons";

import {
  useNavigationToggle,
  useSetNavigationToggle,
} from "../../hooks/useMenuContext/useMenuContext";
import useWindowSize from "../../hooks/use-window-size";
import Colors from "../../styles/colors";
import Typography from "../../styles/typography";

import { useMsal } from "@azure/msal-react";

import DgiLogo from "../../icons/dgi-logo";
import BaneNorLogo from "../../icons/bane-nor-icon";
import AkerBPLogo from "../../icons/akerbp-icon";
import Soprasteria from "../../icons/soprasteria-icon";
import ExecutiveDashboardIcon from "../../icons/executive-dashboard-icon";
import sopraSidebar from "../../icons/sideBar-icon/sopra-steria-sidebar-icon.png";
import ban_norSidebar_night from "../../icons/sideBar-icon/bannor-sidebar-icon-night.png";
import ban_norSidebar_day from "../../icons/sideBar-icon/bannor-sidebar-icon-day.png";
import akr_bp_Sidebar from "../../icons/sideBar-icon/akr-sidebar-icon.png";
import dgi_bp_Sidebar from "../../icons/sideBar-icon/dgi-sidebar-icon.png";
import execdash_Sidebar from "../../icons/sideBar-icon/execdash-sidebar-icon.png";

type MenuItemContainerProps = {
  active: boolean;
};

type SideMenuProps = {
  pinned: boolean;
};

type OpSJsonProps = {
  OpSJson: any;
  className: string;
  theme: any;
};

type PropsS = {
  weight?: string;
  size?: string;
};

const Navbar = styled.div`
  justify-content: start;
  align-items: center;
  height: 3.5rem;
  background-color: #e6f0ec;
`;

const MenuIconOpen = styled(Link)`
  display: flex;
  justify-content: start;
  font-size: 1.5rem;
  margin-left: 2rem;
  color: black;
`;

const MenuIconClose = styled(Link)`
  display: flex;
  justify-content: end;
  font-size: 1.5rem;
  margin-top: 0.75rem;
  margin-right: 1rem;
  color: #ffffff;
`;
const Sidedarbody = styled.div`
  zindex: 99;
  background-color: ${({ theme }) => theme.main};
  color: ${({ theme }) => theme.text.main};
`;
const SidebarMenu = styled.div<SideMenuProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: ${(props: SideMenuProps) => (props.pinned ? "18rem" : "4rem")};
  background-color: ${({ theme }) => theme.main};
  transition: width 0.3s ease 0s;

  overflow: hidden;
  &:hover {
    width: 18rem;
  }
`;

const MenuItemContainer = styled(Link)<MenuItemContainerProps>`
  display: flex;
  text-decoration: none;

  background-color: ${({ theme }) => theme.main};
  transition: width 0.3s ease 0s;
  z-index: 10;
  width: 85%;
  display: inherit;
  margin: 0 auto;
  height: 59px;
  width: 240px;
  ${(props: MenuItemContainerProps) => !props.active} {
    background-color: ${({ theme }) => theme.body.background};

    border-right: ${({ theme }) => theme.mainFont};
    border-style: solid;
    border-width: 0px 6px 0px 0;
    border-radius: 6px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.body.background};
  }
`;

const MenuCollapseButtonContainer = styled.div`
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
`;

const MenuIconContainer = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.text.main};

  .navigation-toggle-icon-open {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    transform: rotate(90deg);

    :hover {
      border-radius: 0.2rem;
      background-color: ${({ theme }) => theme.body.background};
    }
  }

  .navigation-toggle-icon-closed {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    transform: rotate(-90deg);

    :hover {
      border-radius: 0.2rem;
      background-color: ${({ theme }) => theme.body.background};
    }
  }
`;

const MenuIconContainerLogout = styled.div`
  padding: 10px;
  cursor: pointer;
  text-align: left;
  display: flex;
  width: 231px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  &:hover,
  &:active {
    color: ${Colors.darkGray};
  }
  .navigation-toggle-icon-open {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    transform: rotate(90deg);

    :hover {
      border-radius: 0.2rem;
      background-color: #f3f3f3;
    }
  }

  .navigation-toggle-icon-closed {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    transform: rotate(-90deg);

    :hover {
      border-radius: 0.2rem;
      background-color: #f3f3f3;
    }
  }
`;
const MenuIconContainerInformation = styled.div`
  padding: 0px 10px;
  cursor: pointer;
  margin-top: auto;
  text-align: left;
  display: flex;
  width: 231px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  &:hover,
  &:active {
    color: ${Colors.darkGray};
  }
  .navigation-toggle-icon-open {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    transform: rotate(90deg);

    :hover {
      border-radius: 0.2rem;
      background-color: #f3f3f3;
    }
  }

  .navigation-toggle-icon-closed {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    transform: rotate(-90deg);

    :hover {
      border-radius: 0.2rem;
      background-color: #f3f3f3;
    }
  }
`;

const MenuLinkTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.text.main};
  padding: 1rem;
`;

const CloseButton = styled.button`
  position: fixed;
  left: 250px;
  border: none;
  background: white;
`;
const LogoutButton = styled.div`
  background-color: transparent;
  color: "black";
`;

const Sidebar: React.FC<OpSJsonProps> = ({ OpSJson, theme, className }) => {
  const navigationToggle = useNavigationToggle();
  const setNavigationToggle = useSetNavigationToggle();
  const location = useLocation();
  const device = useWindowSize();
  const { instance } = useMsal();
  const [over, setOver] = React.useState(false);
  const [popUpWindow, setPopUpWindow] = React.useState(false);

  useEffect(() => {
    if (device === "tablet") {
      setNavigationToggle(false);
    }
  }, [device]);

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  function get_icon(company_name: string) {
    switch (company_name) {
      case "Digitale Gardermoen IS":
        return (
          <a href="/">
            <DgiLogo
              scale={2}
              altText="Digitale Gardermoen IS logo"
              theme={theme}
            />
          </a>
        );
      case "Aker BP":
        return (
          <a href="/">
            <AkerBPLogo scale={2} altText="Aker logo" theme={theme} />
          </a>
        );
      case "Bane NOR":
        return (
          <a href="/">
            <BaneNorLogo scale={7} altText="Bane NOR logo" theme={theme} />
          </a>
        );
      case "Sopra Steria":
        return (
          <a href="/">
            <Soprasteria
              scale={2.1}
              altText="Sopra Steria logo"
              theme={theme}
            />
          </a>
        );
      default:
        return (
          <a href="/">
            <ExecutiveDashboardIcon
              light={true}
              scale={1}
              altText="Sopra Steria logo"
              theme={theme}
            />
          </a>
        );
    }
  }

  // Side Bar Icon when navigationToggle false:
  function get_side_bar_small_icon(company_name: string, theme) {
    switch (company_name) {
      case "Sopra Steria":
        return (
          <div style={{ width: "50px", marginLeft: "5px", marginTop: "50%" }}>
            <a href="/">
              <img src={sopraSidebar} width="95%" />
            </a>
          </div>
        );
      case "Bane NOR":
        return (
          <div style={{ width: "50px", marginLeft: "5px", marginTop: "50%" }}>
            {theme === "light" ? (
              <a href="/">
                <img src={ban_norSidebar_day} width="80%" />
              </a>
            ) : (
              <a href="/">
                <img src={ban_norSidebar_night} width="80%" />
              </a>
            )}
          </div>
        );
      case "Aker BP":
        return (
          <div style={{ width: "50px", marginLeft: "10px", marginTop: "50%" }}>
            <a href="/">
              <img src={akr_bp_Sidebar} width="80%" />
            </a>
          </div>
        );
      case "Digitale Gardermoen IS":
        return (
          <div style={{ width: "50px", marginLeft: "5px", marginTop: "50%" }}>
            <a href="/">
              <img src={dgi_bp_Sidebar} width="95%" />
            </a>
          </div>
        );
      default:
        return (
          <div style={{ width: "50px", marginLeft: "5px", marginTop: "50%" }}>
            <a href="/">
              <img src={execdash_Sidebar} width="95%" />
            </a>
          </div>
        );
    }
  }
  return (
    <Sidedarbody>
      {popUpWindow && (
        <div
          onClick={() => setPopUpWindow(false)}
          style={{
            position: "fixed",
            top: "0px",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            zIndex: "1",
            alignItems: "center",
            width: "100%",
            height: "100%",
            left: "0px",
          }}
        >
          <MassageContainer
            style={{
              position: "fixed",
              justifyContent: "space-between",
              minHeight: "200px",
              width: "",
            }}
          >
            <div
              style={{
                height: "fit-content",
                width: "100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <PopUpCloseButton onClick={() => setPopUpWindow(false)}>
                {" "}
                <MdOutlineClose
                  style={{
                    width: "25px",
                    height: "25px",
                  }}
                />
              </PopUpCloseButton>
            </div>
            <MassageContainerText1
              // className="FontRoboto"
              style={{
                fontSize: "22px",
                fontFamily: "sans-serif",
                padding: "0px 25px",
                fontWeight: "600",
              }}
            >
              INFORMATION
            </MassageContainerText1>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  height: "fit-content",
                  maxHeight: "500px",
                  overflow: "auto",
                  minHeight: "48px",
                  marginTop: "0px",
                  marginBottom: "10px",
                  display: "flow",
                  alignItems: "start",
                  textAlign: "left",
                  padding: "0px 25px",
                  fontFamily: "sans-serif",
                  justifyContent: "center",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    OpSJson &&
                    OpSJson["mainPage"] &&
                    OpSJson["mainPage"]["companyName"] &&
                    OpSJson["mainPage"]["information"],
                }}
              ></div>
            </div>
          </MassageContainer>
        </div>
      )}
      <div
        className={className}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <SidebarMenu pinned={navigationToggle}>
          {
            <MenuIconContainer
              style={{
                pading: "0px !important",
                marginLeft: "auto",
                marginRight: "auto",
                height: "10%",
              }}
            >
              {navigationToggle
                ? get_icon(
                    OpSJson["mainPage"]
                      ? OpSJson["mainPage"]["companyName"]
                      : ""
                  )
                : over === true
                ? get_icon(
                    OpSJson["mainPage"]
                      ? OpSJson["mainPage"]["companyName"]
                      : ""
                  )
                : get_side_bar_small_icon(
                    OpSJson["mainPage"]
                      ? OpSJson["mainPage"]["companyName"]
                      : "",
                    theme
                  )}
            </MenuIconContainer>
          }
          <MenuCollapseButtonContainer>
            <MenuIconContainer>
              <IconContext.Provider
                value={{
                  size: "1.5em",
                  className: navigationToggle
                    ? "navigation-toggle-icon-open"
                    : "navigation-toggle-icon-closed",
                }}
              >
                <HiDownload
                  onClick={() => setNavigationToggle(!navigationToggle)}
                />
              </IconContext.Provider>
            </MenuIconContainer>
          </MenuCollapseButtonContainer>
          {OpSJson &&
            OpSJson["tabs"] &&
            OpSJson["tabs"].map((item, index) => {
              return item.hasOwnProperty("ShowInSidebar") ? (
                ""
              ) : (
                <MenuItemContainer
                  to={"/" + item.tabName.replace(/ /g, "")}
                  active={
                    (location.pathname === "/" && index === 0) ||
                    location.pathname === `/${item.tabName.replace(/ /g, "")}`
                      ? "true"
                      : ""
                  }
                  key={index + "_" + item.tabName.replace(/ /g, "")}
                >
                  <MenuIconContainer>
                    {{
                      "Operational Status": (
                        <ApplicationsIcon
                          altText="Applications"
                          scale={1.3}
                          light
                          theme={theme}
                        />
                      ),
                      Security: (
                        <SecurityIcon
                          altText="Security"
                          scale={1.3}
                          light
                          theme={theme}
                        />
                      ),
                      Finance: (
                        <ItSpendIcon
                          altText="Finance"
                          scale={1.3}
                          light
                          theme={theme}
                        />
                      ),
                    }[item.tabName] || (
                      <ApplicationsIcon
                        altText="Applications"
                        scale={1.3}
                        light
                        theme={theme}
                      />
                    )}
                  </MenuIconContainer>
                  <MenuLinkTextContainer>
                    <span style={{ fontWeight: "500" }}>{item.tabName}</span>
                  </MenuLinkTextContainer>
                </MenuItemContainer>
              );
            })}
          {
            <MenuIconContainerInformation onClick={() => setPopUpWindow(true)}>
              <LogoutButton className="logout-button">
                <BsExclamation
                  style={{
                    marginLeft: "-8",
                    width: "48px",
                    height: "48px",
                  }}
                />
              </LogoutButton>
              <a
                style={{
                  fontSize: "18px",
                  marginTop: "auto",
                  alignItems: "center",
                  marginBottom: "auto",
                  marginLeft: "13px",
                  fontWeight: "500",
                }}
              >
                INFORMATION
              </a>
            </MenuIconContainerInformation>
          }
          {
            <MenuIconContainerLogout onClick={() => handleLogout()}>
              <LogoutButton className="logout-button">
                <BsBoxArrowRight style={{ width: "38px", height: "38px" }} />
              </LogoutButton>
              <a
                style={{
                  fontSize: "18px",
                  marginTop: "auto",
                  alignItems: "center",
                  marginBottom: "auto",
                  marginLeft: "15px",
                  fontWeight: "500",
                }}
              >
                LOG OUT
              </a>
            </MenuIconContainerLogout>
          }
          {navigationToggle ? (
            <MenuIconContainer
              style={{
                textAlign: "center",
                width: "231px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <p
                style={{
                  borderTop: "1px solid #ccc",
                  fontWeight: "700",
                  fontSize: "20px",
                  paddingTop: "11px",
                }}
              >
                <span>
                  {OpSJson["mainPage"] ? OpSJson["mainPage"]["userName"] : ""}
                </span>
              </p>
            </MenuIconContainer>
          ) : (
            ""
          )}
        </SidebarMenu>
        {/* <CloseButton onClick={showSidebar()}><FiChevronLeft /></CloseButton> */}
      </div>
    </Sidedarbody>
  );
};

export default Sidebar;
