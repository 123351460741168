import Colors from "./colors";

export const lightTheme = {
  // TODO Finish create a more readable layout of all the colors make objects of sections or something
  main: 'white',
  secondary: '#eaf0f6',
  header: 'white',
  openUserMenu: '#ffffff',
  background:'#f3f3f3',

  light: true,

  mainFont: "black",
  secondaryFont: "rgba(0, 0, 0, 0.64)",
  Font:"rgba(0, 0, 0, 0.25)",
  darkborder:"5px 0px 0px 5px",
  lightborder:"5px",
  themebuttondark:"#FCFDFF",
  themebuttonlight:"#F2F4F8",

  text: {
    main: Colors.text,
    secondary: Colors.white,
    normal: Colors.black,
    low:Colors.text,
  },
  
  body: {
    background: '#f3f3f3',
    contentBoxes: 'white',
  },
  
  nav: {
    background: 'white',
    selected: '#f3f3f3',
    border: 'lightgray'
  },

  table: {
    main: 'white',
    text: Colors.black,
    border: '#cfd6de',
    hover: '#C8D3DE',
    important: '#FCB938',
    importantHover: '#E07800'
  },

  shadow: {
    gray: '#cccccc',
  },

  footer: {
    backgroundColor: 'white',
  },

  colors: {
    neutral: '#0041e6',
    positive: '#00AD09',
    positiveShadeLight: '#4fae5b',
    attention: '#e07800',
    negative: '#cf022b',
    inactive: '#c4c4c4',
    white: '#ffffff',
    black: '#1D1D1B',
    gray: '#cccccc',
    darkGray: '#9d9d9d',
    darkerGray: '#4B5158',
    darkestGray: '#1d1d1b',
    lightGray: '#e2e2e2',
    lighterGray: '#f3f3f3',
    lightestGray: '#ffffff',
    text: '#4b5158'
  },

};